import { useQuery } from '@tanstack/react-query';
import { Status } from 'api/generated/graphql';
import dayjs from 'dayjs';

import { useGraphQLClient } from 'hooks';

export const useGetFeaturedRaces = () => {
  const { graphQLClient, graphql, hasToken } = useGraphQLClient();
  const getFeaturedRacesFn = graphql(`
    query GetFeaturedRace($filter: RacesFilter) {
      getRaces(filter: $filter) {
        id
        difficulty
        track {
          id
          trackName
          codeName
          frontendName
          has2D
          has3D
        }
        prizePool
        startTime
        status
        tier {
          name
          rank
        }
      }
    }
  `);

  return useQuery({
    enabled: Boolean(hasToken),
    queryKey: ['featuredRace'],
    queryFn: async () => {
      return await graphQLClient.request(getFeaturedRacesFn, {
        filter: {
          status: [
            Status.Open,
            Status.Live,
            Status.Closed,
            Status.Ended,
            Status.Qualifying,
            Status.Tuning,
            Status.Cancelled,
          ],
          startTime: dayjs().format('YYYY-MM-DD'),
          limit: 1,
        },
      });
    },
  });
};

export default useGetFeaturedRaces;
