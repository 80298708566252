/* eslint-disable react-hooks/exhaustive-deps */
import Head from 'next/head';
import { Flex, Heading, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import LogRocket from 'logrocket';

import {
  RGlobalLayout,
  RPageHeading,
  RLoadingAnimation,
  RGarageEmptyAlert,
} from 'components';
import routes from 'routes';
import { useGetGarage, useUserSettings } from 'hooks';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUser } from 'context';
import { GettingStarted } from 'components/HomeScreen/GettingStarted';
import { NewItemsPanel } from 'components/HomeScreen/NewItems';
import { TicketsPanel } from 'components/HomeScreen/TicketTypes';
import { ViewCarsPanel } from 'components/HomeScreen/ViewCars';
import { RecentActivity } from 'components/HomeScreen/RecentActivity';
import { FeaturedRacePanel } from 'components/HomeScreen/FeaturedRace';
import newBg from 'public/images/backgrounds/new_background.webp';
import { Users } from 'api/generated/graphql';

export const DefaultPage = () => {
  const router = useRouter();
  const { data, isLoading } = useGetGarage();
  const needsCar =
    !isLoading && (data?.getGarage.length === 0 || !data?.getGarage.length);

  const { tierUpdatedCars } = useUser();
  const { getUserSettings } = useUserSettings();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (tierUpdatedCars && tierUpdatedCars.length > 0) {
      router.push(routes.rewards());
    }
  }, [tierUpdatedCars]);

  useEffect(() => {
    const user = getUserSettings.data?.getUser as Users | undefined;

    if (!user || process.env.NEXT_PUBLIC_ENV !== 'prod') return;

    LogRocket.identify(user.id, {
      name: user.playerName || '',
      email: user.email,
      // Custom user variables
      country: user.countryFlag || '',
      totalEarnings: user.totalEarnings || 0,
      totalRaces: user.totalRaces || 0,
      walletChain: user.walletChain || '',
      blockPaidRaces: user.blockPaidRaces || false,
      testRacesAllowed: user.testRacesAllowed || false,
    });
  }, [getUserSettings.data]);

  return (
    <>
      <RGlobalLayout
        onOpenAnyDrawer={isOpen}
        needsCar={needsCar}
        bgImageSrc={newBg.src}
        bgAnimationPulse={true}
      >
        <Head>
          <title>Racino.io</title>
        </Head>

        {/* <FeaturedRaceHeader /> */}
        <RPageHeading>
          <Heading as="h2" size="sm" color="white.80" textTransform="uppercase">
            Welcome to your dashboard
          </Heading>
        </RPageHeading>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" height="80%">
            <RLoadingAnimation />
          </Flex>
        )}

        <RGarageEmptyAlert show={!isLoading && needsCar} />

        {!isLoading && (
          <Grid
            templateRows={{ base: 'repeat(6, 1fr)', md: 'repeat(6, 1fr)' }}
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            gap={4}
            height="75vh"
          >
            {/* GETTING STARTED */}
            <GridItem
              rowSpan={{ base: 1, md: 6 }}
              colSpan={{ base: 1, md: 1 }}
              h={{ base: '400px', md: 'auto' }}
              position="relative"
              id="getting-started"
            >
              <GettingStarted />
            </GridItem>

            {/* VIEW CARS */}
            <GridItem
              position="relative"
              rowSpan={{ base: 1, md: 3 }}
              h={{ base: '300px', md: 'auto' }}
              id="view-cars"
            >
              <ViewCarsPanel />
            </GridItem>

            {/* MARKETPLACE LINK */}
            <GridItem
              position="relative"
              rowSpan={{ base: 1, md: 2 }}
              h={{ base: '300px', md: 'auto' }}
              id="marketplace"
            >
              <NewItemsPanel />
            </GridItem>

            {/* TICKETS */}
            <GridItem
              position="relative"
              rowSpan={{ base: 1, md: 2 }}
              h={{ base: '300px', md: 'auto' }}
              id="tickets"
            >
              <TicketsPanel />
            </GridItem>

            {/* FEATURED RACE */}
            <GridItem
              position="relative"
              rowSpan={{ base: 1, md: 3 }}
              h={{ base: '300px', md: 'auto' }}
              id="featured-race"
            >
              <FeaturedRacePanel
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
              />
            </GridItem>

            <GridItem
              position="relative"
              rowSpan={{ base: 1, md: 2 }}
              h={{ base: '300px', md: 'auto' }}
              id="activity"
            >
              <RecentActivity showButton />
            </GridItem>
          </Grid>
        )}
      </RGlobalLayout>
    </>
  );
};

export default DefaultPage;
